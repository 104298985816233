import request from "@/api/config.js";

export function getUserSprints(user_id, job_id) {
  return request({
    url: `sprints/get-sprints/${user_id}/${job_id}`,
    method: "get",
  });
}

export function adminGetAllUsersSprints(data) {
  return request({
    url: `sprints/admin/get/all/sprints`,
    method: "post",
    timeout: 15000,
    data,
  });
}

export function adminGetAllUsersSprintsCounter(data) {
  return request({
    url: `sprints/admin/get/all/sprints/counter`,
    method: "post",
    timeout: 15000,
    data,
  });
}

export function adminGetAllOneUserSprints(user_id) {
  return request({
    url: `sprints/admin/get-all/sprints/by/user/${user_id}`,
    method: "get",
  });
}

export function createSprintOnMonday(data) {
  return request({
    url: `sprints/sprint/create`,
    method: "post",
    data,
  });
}

export function checkSprint(data) {
  return request({
    url: `sprints/sprint/check`,
    method: "post",
    data,
  });
}

export function deleteSprintMaterial(mat_id) {
  return request({
    url: `sprints/sprint/material/delete/${mat_id}`,
    method: "get",
  });
}

export function uploadSprintMaterials(sprint_id, data) {
  return request({
    url: `sprints/sprint/${sprint_id}/upload/materials`,
    method: "post",
    data,
  });
}

export function setSprintUserComment(sprint_id, data) {
  return request({
    url: `sprints/sprint/${sprint_id}/change/comment`,
    method: "post",
    data,
  });
}

export function updateSprint(data) {
  return request({
    url: `sprints/sprint/update/info`,
    method: "post",
    data,
  });
}
